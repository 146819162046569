<template>
  <div class="inline-editor">
    <ckeditor
      :editor="editor"
      v-model="editorData"
      :config="editorConfig"
      @ready="onReady"
      @blur="onBlur"
    ></ckeditor>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

export default {
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '...',
    },
  },
  data() {
    return {
      editor: InlineEditor,
      editorConfig: {
        placeholder: this.placeholder,
        startupFocus: true,
        language: 'de',
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'undo',
            'redo',
            '|',
            'heading',
          ],
        },
      },
      editorData: '',
    };
  },
  methods: {
    onReady(editor) {
      this.editorData = this.value ? this.value : '';
      editor.editing.view.focus();
    },
    onBlur(val) {
      this.$emit('blur', val);
    },
  },
  watch: {
    /* Make sure input gets updated when parent changes */
    // value(newValue, oldValue) {
    // //   console.log('newValue', newValue);
    //   if (newValue) {
    //     this.editorData = newValue;
    //   }
    // },
    editorData: function(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.ck-content {
  min-height: 25px;
  padding-top: 2px;
}
</style>
