<template>
  <div class="hidden-editor" v-on:click="activateInput">
    <span
      v-if="!active"
      class="solid-text"
      :class="{ empty: empty }"
      v-html="value"
      ref="solidText"
    ></span>
    <InlineEditor
      class="edit-text"
      :class="{ empty: empty }"
      v-if="active"
      v-model="inputValue"
      @input="updateInput()"
      @blur="disableInput()"
      :placeholder="placeholder"
    ></InlineEditor>
  </div>
</template>

<script>
import InlineEditor from './Editor/InlineEditor';

export default {
  name: 'HiddenEditor',
  components: { InlineEditor },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '...',
    },
  },
  watch: {
    /* Make sure input gets updated when parent changes */
    // value(newValue, oldValue) {
    //     console.log('newValue', newValue);
    //   if (newValue) {
    //     this.inputValue = newValue;
    //   }
    // },
  },
  data() {
    return {
      inputValue: '',
      inputPlaceholder: '',
      activeInput: false,
    };
  },
  computed: {
    active() {
      return this.activeInput || this.inputValue === '';
    },
    empty() {
      return this.inputValue === '';
    },
  },
  mounted() {
    this.inputValue = this.value;
    this.inputPlaceholder = this.placeholder;
  },
  methods: {
    updateInput() {
      this.$emit('input', this.inputValue);
    },
    activateInput(editor) {
      this.activeInput = true;
    },
    disableInput() {
      this.activeInput = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden-editor {
  min-height: 20px;
  position: relative;
}
.hidden-editor .edit-text:focus {
  border: 1px solid $gray-400;
  box-shadow: 0.5px 0.5px 3px rgba(0, 0, 0, 0.1) inset;
  border-radius: 0;
}
.hidden-editor:hover::after {
  font-family: 'Line Awesome Free';
  content: '\f044';
  border: none;
  width: auto;
  height: auto;
  vertical-align: baseline;
  opacity: 0.9;
  font-size: 16px;
  position: absolute;
  left: -20px;
  top: -5px;
}
.hidden-editor .empty {
  border: 1px solid $gray-400;
}
</style>
