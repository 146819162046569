<template>
  <div class="grid-document-header" ref="docHeader">
    <div class="grid-item item document-header-left">
      <div class="document-address-header">
        Q Power Solution GmbH | Neufahrner Str. 14 | 85375 Neufahrn
      </div>
      <div class="document document-address">
        <HiddenEditor v-model="offer.address" placeholder="Adresse" />
      </div>
    </div>

    <div class="grid-item document-logo">
      <div class="document-author-logo">
        <img src="../../assets/qps.png" alt="SSP Elektro GmbH" />
      </div>
    </div>
    <div class="grid-item document-header-right">
      <div class="document-author-address">
        Q Power Solution GmbH
        <br />Neufahrner Str. 14 <br />85375 Neufahrn
        <br />
        <br />T: +49 (0) 8165 907 49 93 <br />
        office@ssp-elektro.com <br />www.ssp-elektro.com
      </div>
    </div>

    <div class="grid-item document-meta">
      <div>
        <strong>{{ $t('offer') }}:&nbsp;</strong>
        <span v-if="offer.number">{{ offer.number }}</span>
        <span v-else>(autom. generiert)</span>
      </div>
      <div>
        <strong>{{ $t('date') }}:&nbsp;</strong>
        <HiddenInput type="text" icon="right" v-model="offer.date"></HiddenInput>
      </div>
      <div>
        <strong>{{ $t('editor') }}:&nbsp;</strong>
        <HiddenInput type="text" icon="right" v-model="offer.editor"></HiddenInput>
      </div>
      <div>
        <strong>{{ $t('phone') }}:&nbsp;</strong>
        <HiddenInput type="text" icon="right" v-model="offer.phone"></HiddenInput>
      </div>
      <div>
        <strong>{{ $t('email') }}:&nbsp;</strong>
        <HiddenInput type="text" icon="right" v-model="offer.email"></HiddenInput>
      </div>
    </div>
    <div class="grid-item document-title">
      <HiddenInput type="text" v-model="offer.title" placeholder="Titel" />
    </div>
    <div class="grid-item document-introduction">
      <HiddenEditor v-model="offer.introduction" placeholder="Anrede" />
      <i class="fa fa-trash-o trash-introduction" @click="onIntroductionTrash" />
    </div>
  </div>
</template>

<script>
import HiddenInput from '../../components/HiddenInput';
import HiddenEditor from '../../components/HiddenEditor';
export default {
  name: 'DocumentHeader',
  components: { HiddenInput, HiddenEditor },
  props: {
    offer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    hideIntroduction() {
      return this.offer.introduction === '' || !this.offer.introduction ? true : false;
    },
  },
  mounted() {},
  methods: {
    onIntroductionTrash(value) {
      // this.$emit("documentIntroductionTrashed", this.$vnode.key);
      this.offer.introduction = '';
    },
  },
};
</script>

<style lang="scss" scoped>
/** Grid Layout for document header */
.grid-document-header {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1cm;
  grid-template-rows: auto;
  grid-template-areas:
    'logo . .  .'
    '. . header-right .'
    'header-left . header-right .'
    '. . meta .'
    'title title title .'
    'intro intro intro .';
  column-gap: 5px;
  row-gap: 10px;
}
.grid-item {
  border: 0px solid $danger;
  margin-bottom: 30px;
}
.document-header-left {
  grid-area: header-left;
}
.document-logo {
  grid-area: logo;
  text-align: left;
}
.document-header-right {
  grid-area: header-right;
  text-align: right;
}
.document-meta {
  grid-area: meta;
  text-align: right;
}
.document-title {
  grid-area: title;
  margin-bottom: 5px;
}
.document-introduction {
  grid-area: intro;
  margin-bottom: 0;
}

/** Styling for document header */
::v-deep .document p {
  margin: 0 !important;
  margin-bottom: 0;
}
.document-logo img {
  width: 200px;
}
.document-address-header {
  font-size: 5pt;
  padding-bottom: 10px;
}
.document-author-address {
  // font-family: 'Open Sans', sans-serif;
  // font-stretch: ultra-condensed;
  font-weight: 700;
  line-height: 1.6em;
  text-align: right;
  // text-transform: uppercase;
  // padding-left: 38%;
  font-size: 7pt;
  color: $gray-600;
}
.document-title {
  font-size: 14pt;
}
.document-introduction {
  position: relative;
}
.document-introduction.hide {
  visibility: hidden;
}
.document-introduction:hover {
  border: 1px solid $gray-200;
}
.trash-introduction {
  display: none;
  position: absolute;
  right: 5px;
  top: 2px;
  cursor: pointer;
  color: $danger;
}
.document-introduction:hover .trash-introduction {
  display: inline;
}
</style>
