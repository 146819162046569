<template>
  <div class="page A4">
    <div class="grid-item document-logo">
      <div class="document-author-logo">
        <img src="../../assets/qps.png" alt="SSP Elektro GmbH" />
      </div>
    </div>

    <div class="terms">
      <HiddenEditor v-model="offer.terms" placeholder="Weitere Informationen" />
    </div>

    <div class="document-signature">
      <div>Kunde</div>
      <div>Angebot angenommen (Datum, Unterschrift)</div>
      <div></div>
      <div class="signature"></div>
      <div></div>
      <div>Herr Max Mustermann</div>
    </div>
    <div class="document-signature">
      <div>Q Power Solution GmbH</div>
      <div>Angebot angenommen (Datum, Unterschrift)</div>
      <div></div>
      <div class="signature"></div>
      <div></div>
      <div>Herr Andreas Traunfellner</div>
    </div>
  </div>
</template>

<script>
import HiddenEditor from '../../components/HiddenEditor';

export default {
  components: { HiddenEditor },
  props: {
    fields: Array,
    image: String,
    offer: Object,
  },
};
</script>

<style lang="scss" scoped>
/** DIN A4 Page Format */
.A4 {
  background: white;
  background-size: cover;
  background-repeat: no-repeat;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0;
  padding: 1cm 1cm 1cm 2.2cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  overflow-y: none;
  box-sizing: border-box;
  margin-bottom: 1em;
}
.page {
  position: relative;
}

.document-logo img {
  width: 200px;
}

.terms {
  margin: 3em 1cm 2em 0;
}
.document-signature {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
  margin: 2em 1cm 2em 0;
}
.document-signature div {
  line-height: 25px;
}
.signature {
  margin-top: 3.5em;
  border-bottom: 1px solid black;
}
@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    background-size: cover;
    background-repeat: no-repeat;
    border: 0px solid red;
    page-break-after: always;
    margin: 0;
    padding: 0;
    background-position: center center;
    position: relative;
    width: 100%;
    height: 100%;
    // top: 0;
    // left: 0;
  }
  .field {
    position: absolute;
    // border: 1px solid rebeccapurple;
  }
}
</style>
