<template>
  <div class="page A4">
    <div class="grid-item document-logo">
      <div class="document-author-logo">
        <img src="../../assets/qps.png" alt="SSP Elektro GmbH" />
      </div>
    </div>

    <div class="bold">
      Angebot: <HiddenInput type="text" icon="right" v-model="offer.customerName"></HiddenInput>
      , Angebotsnr.:
      <span v-if="offer.number">{{ offer.number }}</span> <span v-else>(autom. generiert)</span> -
      Details
    </div>

    <div class="grid-document-position document-position-header">
      <div class="grid-item text-center">
        {{ $t('position') }}
      </div>
      <div class="grid-item text-center">
        {{ $t('quantity') }}
      </div>
      <div class="grid-item text-left">
        {{ $t('description') }}
      </div>
    </div>

    <div class="grid-document-position" v-if="systemPosition">
      <div class="grid-item text-center">
        01
      </div>
      <div class="grid-item text-center">
        {{ systemPosition.solarPanelCount }}
      </div>
      <div class="grid-item text-left">
        <div>
          <strong
            >{{ systemPosition.solarPanel.manufacturer }},
            {{ systemPosition.solarPanel.type }}</strong
          >
        </div>
        <div>{{ systemPosition.solarPanel.power }} Wp/Modul</div>
        <div>Details entsprechend beigefügtem Datenblatt</div>
      </div>

      <div class="grid-item text-center">
        02
      </div>
      <div class="grid-item text-center">
        {{ systemPosition.inverterCount }}
      </div>
      <div class="grid-item text-left">
        <div>
          <strong
            >{{ systemPosition.inverter.manufacturer }}, {{ systemPosition.inverter.type }}</strong
          >
        </div>
        <div>Details entsprechend beigefügtem Datenblatt</div>
      </div>

      <div class="grid-item text-center">
        03
      </div>
      <div class="grid-item text-center">
        {{ systemPosition.batteryCount }}
      </div>
      <div class="grid-item text-left">
        <div>
          <strong
            >{{ systemPosition.battery.manufacturer }}, {{ systemPosition.battery.type }}</strong
          >
        </div>
        <div>{{ systemPosition.battery.capacity }} kWh/System</div>
        <div>Details entsprechend beigefügtem Datenblatt</div>
      </div>

      <div class="grid-item text-center">
        04
      </div>
      <div class="grid-item text-center">
        inklusive
      </div>
      <div class="grid-item text-left">
        <div>
          <strong>Material für Montage und Anschluss </strong>
        </div>
        <div>
          Vorbereitung, Lieferung und Verbau von allen für die Montage und den Anschluss der
          PV+Speicher Anlage erforderlichen anlagebezogenen Materialen.
        </div>
      </div>
      <div class="grid-item text-center">
        05
      </div>
      <div class="grid-item text-center">
        inklusive
      </div>
      <div class="grid-item text-left">
        <div>
          <strong>Planung, Montage und Anschluss der PV+Speicher Anlage </strong>
        </div>
        <div>
          Bau- und Anschlussplanung<br />
          Baustellenbegutachtung<br />
          Arbeitsschutzgerüst oder Montageschnellgerüst inkl. aller Personenschutzmaßnahmen für den
          Montagezeitraum<br />
          Montage der Unterkonstruktion<br />
          Montage und Verkabelung der PV Module<br />
          Montage und Verkabelung des/der Wechselrichter/s (DC)<br />
          Montage und Verkabelung des Speichersystems<br />
          Anschluss des Batteriewechselrichters<br />
          Anschluss der Batterien und der Sensoren im Verteilerkasten entsprechend den Angaben des
          Versorgernetzbetreibers<br />
          Errichtung und Anschluss der Unterverteilung<br />
          Verlegen der Zuleitung vom Wechselrichter zum Zähleranschlusskasten und Installation des
          Potentialausgleichs<br />
          Fertigstellung des technischen Netzanschlusses der PV+Speicher Anlage (AC) <br />Anmeldung
          der PV+Speicheranlage beim Energieversorgungsunternehmen (EVU)
        </div>
      </div>
    </div>
    <div>
      Sollte der Netzbetreiber zusätzliche Anforderungen für den Anschluss haben, so werden diese
      gesondert nach Aufwand berechnet.
    </div>
  </div>
</template>

<script>
import HiddenInput from '../../components/HiddenInput';

export default {
  components: { HiddenInput },
  props: {
    fields: Array,
    image: String,
    offer: Object,
  },
  computed: {
    systemPosition() {
      if (this.offer.systemPositions.length > 0) {
        return this.offer.systemPositions[0];
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
/** DIN A4 Page Format */
.A4 {
  background: white;
  background-size: cover;
  background-repeat: no-repeat;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0;
  padding: 1cm 1cm 1cm 2.2cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  overflow-y: none;
  box-sizing: border-box;
  margin-bottom: 1em;
}
.page {
  position: relative;
}
.document-logo {
  margin-bottom: 2em;
}
.document-logo img {
  width: 200px;
}
.bold {
  font-weight: bold;
}

/** Grid layout for document positions */
.grid-document-position {
  padding-right: 1cm;
  display: grid;
  grid-template-columns: 1fr 1fr 8fr;
  grid-template-rows: auto;
  grid-column-gap: 5px;
  grid-row-gap: 1em;
  margin-bottom: 2em;
}
.document-position-header {
  border-bottom: 1px solid black;
  margin-top: 2em;
}
.document-position-header {
  border-bottom: 1px solid black;
  margin: 2em 0 1em 0;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    background-size: cover;
    background-repeat: no-repeat;
    border: 0px solid red;
    page-break-after: always;
    margin: 0;
    padding: 0;
    background-position: center center;
    position: relative;
    width: 100%;
    height: 100%;
    // top: 0;
    // left: 0;
  }
  .field {
    position: absolute;
    // border: 1px solid rebeccapurple;
  }
}
</style>
