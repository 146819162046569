<template>
  <div class="hidden-input" :class="icon" v-on:click="activateInput">
    <div v-if="!active" class="solid-text">
      <span v-if="type == 'text'">{{ value }}</span>
      <span v-else>{{ $n(value, type) }}</span>
    </div>
    <input
      class="edit-text"
      ref="input"
      v-model="inputValue"
      v-if="active"
      @input="updateInput()"
      v-on:blur="disableInput"
      v-on:keyup.enter="disableInput"
      type="type"
      :placeholder="inputPlaceholder"
    />
  </div>
</template>

<script>
// Valid supported input types
const TYPES = [
  'text',
  'password',
  'email',
  'number',
  'url',
  'tel',
  'search',
  'range',
  'color',
  'date',
  'time',
  'datetime',
  'datetime-local',
  'month',
  'week',
  'currency',
];

export default {
  name: 'HiddenInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator: (type) => type == 'text' || type == 'decimal' || type == 'currency',
    },
    placeholder: {
      type: String,
      default: '...',
    },
    icon: {
      type: String,
      default: 'left',
    },
  },
  watch: {
    /* Make sure input gets updated when parent changes */
    value(newValue, oldValue) {
      this.inputValue = newValue;
    },
  },
  data() {
    return {
      inputValue: '',
      activeInput: false,
      inputPlaceholder: '',
    };
  },
  computed: {
    active() {
      return this.activeInput || this.value === '';
    },
  },
  mounted() {
    this.inputValue = this.value;
    this.inputPlaceholder = this.placeholder;
  },
  methods: {
    updateInput() {
      this.$emit('input', this.inputValue);
    },
    activateInput() {
      this.activeInput = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    disableInput() {
      this.activeInput = false;
    },
    arrayIncludes(array, value) {
      return array.indexOf(value) !== -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden-input {
  min-height: 20px;
  display: inline;
  position: relative;
  // line-height: 25px;
}
.hidden-input .solid-text {
  min-width: 25px;
  line-height: 25px;
  // border: 1px solid greenyellow;
  display: inline-block;
}
.hidden-input .solid-text:hover {
  border: 1px solid $gray-200;
}
.hidden-input:hover::after {
  font-family: 'Line Awesome Free';
  content: '\f044';
  border: none;
  width: auto;
  height: auto;
  vertical-align: baseline;
  opacity: 0.9;
  font-size: 16px;
  left: -20px;
  position: absolute;
  top: -5px;
}
.hidden-input.right:hover::after {
  right: -20px;
  top: -7px;
}
.hidden-input.left:hover::after {
  left: -20px;
  top: -5px;
}
.hidden-input input {
  width: 48%;
  border: 1px solid $gray-400;
  box-shadow: 0.5px 0.5px 3px rgba(0, 0, 0, 0.1) inset;
  padding: 0 8px;
}
.hidden-input input::placeholder {
  color: $gray-500;
}
.hidden-input input:focus {
  outline: none;
}
</style>
