<template>
  <div class="page A4" v-if="offer">
    <DocumentHeader ref="documentHeader" :offer="offer" />

    <div class="document-system-position bold">
      <div class="grid-left">Produktionsleistung der PV-Anlage:</div>
      <div class="grid-right">{{ $n(systemPosition.kwp, 'decimal') }} kWp</div>
      <div class="grid-left">Kapazität des Speichersystems:</div>
      <div class="grid-right">{{ $n(systemPosition.kwh, 'decimal') }} kWh</div>
    </div>

    <div>
      Unser Angebot umfasst die Planung, Lieferung, Montage, Netzeanbindung und Inbetriebnahme Ihrer
      neuen PV+Speicher-Anlage zur eigenen Stromerzeugung und -speicherung.Details zum
      Angebotsumfang sind umseitig dargestellt.
    </div>

    <div class="document-system-position bold">
      <div class="grid-left">Preis Angebot gesamt netto</div>
      <div class="grid-right">{{ $n(systemPosition.price, 'currency') }}</div>
      <div class="grid-left">Umsatzsteuer 19%</div>
      <div class="grid-right">{{ $n(systemPosition.vat, 'currency') }}</div>
      <div class="grid-left border-top">Preis Angebot gesamt brutto</div>
      <div class="grid-right border-top">{{ $n(systemPosition.overallPrice, 'currency') }}</div>
    </div>

    <div class="document-conditions-position">
      <div>Lieferzeit:</div>
      <div>nach Abspache</div>
      <div>Preisbindung:</div>
      <div>2 Wochen nach Datum des verbindlichen Angebotes</div>
      <div>Zahlungskonditionen:</div>
      <div>50% innerhalb von 5 Werktagen nach Zeichnung des Angebotes gegen 1. Teilrechnung</div>
      <div></div>
      <div>
        50% innerhalb von 5 Werktagen nach Fertigstellung der Installation gegen 2. Teilrechnung
      </div>
      <div>Gewährleistungszeit:</div>
      <div>12 Monate nach Inbetriebnahme</div>
    </div>
  </div>
</template>

<script>
import DocumentHeader from '@/components/Document/DocumentHeader';

export default {
  name: 'OfferPrintFirstPage',
  components: { DocumentHeader },
  props: {
    fields: Array,
    image: String,
    offer: Object,
  },
  computed: {
    systemPosition() {
      if (this.offer.systemPositions.length > 0) {
        return {
          kwp:
            (this.offer.systemPositions[0].solarPanel.power *
              this.offer.systemPositions[0].solarPanelCount) /
            1000,
          kwh:
            this.offer.systemPositions[0].battery.capacity *
            this.offer.systemPositions[0].batteryCount,
          price: this.offer.systemPositions[0].price,
          vat: this.offer.systemPositions[0].price * 1.19 - this.offer.systemPositions[0].price,
          overallPrice: this.offer.systemPositions[0].price * 1.19,
        };
      }
      return { kwp: 0, kwh: 0, price: 0, vat: 0, overallPrice: 0 };
    },
  },
};
</script>

<style lang="scss" scoped>
/** DIN A4 Page Format */
.A4 {
  background: white;
  background-size: cover;
  background-repeat: no-repeat;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0;
  padding: 1cm 1cm 1cm 2.2cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  overflow-y: none;
  box-sizing: border-box;
  margin-bottom: 1em;
}
.page {
  position: relative;
  //   font-size: 10pt;
}
.document-system-position {
  display: grid;
  grid-template-columns: auto 4cm;
  column-gap: 5px;
  row-gap: 5px;
  margin: 2em 1cm 2em 0;
}

.document-system-position .grid-right {
  text-align: right;
}
.document-system-position .border-top {
  border-top: 1px solid black;
}
.document-system-position.bold {
  font-weight: bold;
}

.document-conditions-position {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 5px;
  margin: 2em 1cm 2em 0;
}

.field {
  position: absolute;
  // line-height: 12px;
  // border: 1px solid red;
  font-size: 11px;
  padding: 2px 0 0 4px;
}
.dragCheckbox {
  line-height: 8px;
  font-weight: bold;
  font-size: 14px;
  padding: 0;
}
.field img {
  max-width: 100%;
  max-height: 100%;
}
@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    background-size: cover;
    background-repeat: no-repeat;
    border: 0px solid red;
    page-break-after: always;
    margin: 0;
    padding: 1cm 1cm 1cm 2.2cm;
    background-position: center center;
    position: relative;
    width: 100%;
    height: 100%;
    // top: 0;
    // left: 0;
  }
  .field {
    position: absolute;
    // border: 1px solid rebeccapurple;
  }
}
</style>
